import React, {Component} from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import TransitionedBlock from "../HelperComponents/TransitionedBlock/TransitionedBlock";
import Pagination from '../HelperComponents/Pagination/Pagination';
import AddIcon from '../../assets/image/add.png';

import { getAllResidues } from "../../actions/disposalBalancesActions";

import './DisposalBalances.scss';
import moment from "moment/moment";

class DisposalBalances extends Component {

    state = {
        activePage: 1
    };

    componentDidMount = () => {
        this.doRequest();
    };

    doRequest = () => {
        const { getAllResidues } = this.props;
        const { activePage } = this.state;

        let arr = [];
        arr.push(`page_size=25`);
        arr.push(`page=${activePage}`);

        getAllResidues(arr).then(res => {
            if (res.payload) {
                this.setState({ loading: false })
            }
        });
    };

    changePage = (pageNumber) => {
        this.setState({ activePage: pageNumber });
        setTimeout(() => {
            this.doRequest();
        }, 0);
    };

    render(){
        const { disposal_balances:{ all_residues }} = this.props;
        const { activePage } = this.state;
        if (!all_residues.results) return null;

        return (
            <TransitionedBlock>
                <div className="disposal_balances container">
                    <div className="flex-center-btw title-block-page">
                        <div className="title-page">
                            <h1>Выдача остатков</h1>
                        </div>
                        <div className="add_button">
                            <Button
                                component={Link}
                                to="new-issue"
                                classes={{
                                    root: 'add_btn'
                                }}
                            >
                                Добавить
                                <img src={AddIcon} alt="edit icon"/>
                            </Button>
                        </div>
                    </div>
                    <div className="page_block">
                        <div className="disposal_balances_table">
                            <div className="table_container transactions_columns">
                                <div className="table_header">
                                    <div className="table_row">
                                        <div className="row_item">Дата/время</div>
                                        <div className="row_item">Кому выдали</div>
                                        <div className="row_item">К-во выданных товаров</div>
                                        <div className="row_item">Комментарий</div>
                                        <div className="row_item">Номер выдачи заказа</div>
                                    </div>
                                </div>
                                <div className="table_body">
                                    {all_residues.results.map((item, idItem) => (
                                        <div className="table_row" key={idItem}>
                                            <div className="row_item">{moment(item.date).format('DD.MM.YYYY HH:mm')}</div>
                                            <div className="row_item">{item.company}</div>
                                            <div className="row_item">{item.product_count}</div>
                                            <div className="row_item">{item.comment}</div>
                                            <div className="row_item">{item.document_number}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {all_residues.count && all_residues.count > 25 ?
                                <div>
                                    <Pagination
                                        active={activePage}
                                        pageItemsCount={25}
                                        pageTotalCount={all_residues.count}
                                        onChange={(e) => this.doRequest(e.selected + 1)}
                                    />
                                </div>
                                :
                                null
                            }
                        </div>
                    </div>
                </div>
            </TransitionedBlock>
        );
    }
}

const mapStateToProps = ({disposal_balances}) => ({
    disposal_balances
});

const mapDispatchToProps = {
    getAllResidues
};

export default connect(mapStateToProps, mapDispatchToProps)(DisposalBalances);