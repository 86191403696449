import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment/moment";
import IconButton from "@material-ui/core/IconButton";
import BackLink from "../../HelperComponents/BackLink/BackLink";
import DefaultButton from "../../Buttons/DefaultButton/DefaultButton";
import DialogComponent from "../../HelperComponents/DialogComponent/DialogComponent";
import TransitionedBlock from "../../HelperComponents/TransitionedBlock/TransitionedBlock";
import Loader from "../../HelperComponents/Loader/Loader";

import {
  getSpecificRefundRequest,
  patchRefundRequestStatus,
  postRefundRequestConfirmStatus,
  postDialogMessage,
  postRefundRequestOffers,
} from "../../../actions/refundRequestsActions";
import {
  getStatusRefundRequest,
  getStatusColor,
  splitByCommas,
} from "../../../helpers/functions";

import CloseDialogIcon from "../../../assets/image/Mask.png";
import TelegramIcon from "../../../assets/image/telegram.png";
import ViberIcon from "../../../assets/image/viber.png";
import WhatsappIcon from "../../../assets/image/whatsapp.png";
import FileIcon from "../../../assets/image/file_icon.png";
import "./RefundRequestsItems.scss";
import CommentComponent from "../../HelperComponents/CommentComponent/CommentComponent";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import UploadFile from "../../HelperComponents/UploadFile/UploadFile";
import { withUploadFile } from "../../../hocs/withUploadFile";
import RefundRequestsStatusForm from '../RefundRequestsStatusForm/RefundRequestsStatusForm';

class RefundRequestsItems extends Component {
  state = {
    loading: true,
    open: false,
    openDialog: false,
    id: null,
    loadingBtn: false,
    message: "",
    sendMessageLoading: false,
    files: null,
  };

  componentDidMount = () => {
    this.doRequest();
  };

  doRequest = () => {
    const {
      match: { params },
      getSpecificRefundRequest,
    } = this.props;
    getSpecificRefundRequest(params.id).then((res) => {
      if (res.payload && res.payload.status && res.payload.status === 200) {
        this.setState({
          loading: false,
          id: res.payload.data.id,
        });
      } else {
        this.props.history.push({ 
          pathname: '/admin/refund-requests',
          state: {
            isVsibleSnackBar: true,
            snackBarMessage: 'При получении данных по заявке на возврат возникла ошибка. Повторите попытку позже',
          }});
        }
    });
  };

  toggleDialog = () => {
    this.setState(({ open }) => ({
      open: !open,
    }));
  };

  toggleDialogOpen = () => {
    this.setState(({ openDialog }) => ({
      openDialog: !openDialog,
    }));
  };

  handleDialog = (status, { date, number, upd_number, offers } = {} ) => {
    const {
      match: { params },
      patchRefundRequestStatus,
      refund_requests: { refund_request },
      postRefundRequestConfirmStatus,
      postRefundRequestOffers
    } = this.props;
    const data = { status: status };
    this.setState({
      open: false,
      openDialog: false,
      loading: true,
    });
    if (status === 'complete' && refund_request.company_role !== 'wholesale_customer') {
      this.setState({ loadingBtn: true });
      postRefundRequestConfirmStatus(params.id, { date, number, upd_number }).then((res) => {
        this.setState({ loadingBtn: false });
        if (res.payload && res.payload.status) {
          this.doRequest();
        }
      });
    } else if (status === 'completeByPriceList' && refund_request.company_role !== 'wholesale_customer') {
      this.setState({ loadingBtn: true });
      postRefundRequestOffers(params.id, offers).then((res) => {
        this.setState({ loadingBtn: false });
        if (res.payload && res.payload.status) {
          this.doRequest();
        }
      });
    } else {
      patchRefundRequestStatus(params.id, data).then((res) => {
        this.setState({ loadingBtn: false });
        if (res.payload && res.payload.status) {
          this.doRequest();
        }
      });
    }
  };

  downloadFile = (file) => {
    if (window.location.host !== "admin.mexcar.ru") {
      window.open(`https://api.testmexcar.ru${file}`);
    } else {
      window.open(`https://api.mexcar.ru${file}`);
    }
  };

  handleChangeTextarea = (e) => {
    this.setState({ message: e.target.value });
  };

  prepareDataToSend = () => {
    const {
      match: { params },
    } = this.props;
    const { message, files } = this.state;
    const { setFromData } = this.props.withUploadFile;
    setFromData(+params.id, message, files);
  };

  uploadFile = (files) => {
    this.setState({
      files,
    });
  };

  sendMessage = () => {
    const { postDialogMessage } = this.props;
    const { formData } = this.props.withUploadFile;
    this.setState({ sendMessageLoading: true });
    this.prepareDataToSend();
    postDialogMessage(formData)
      .then((res) => {
        if (res.payload && res.payload.status) {
          this.setState({ sendMessageLoading: false, message: "" });
          this.doRequest();
        }
      })
      .catch(() => {
        this.setState({ sendMessageLoading: false });
      });
  };

  hasFiles() {
    const {
      refund_requests: { refund_request }
    } = this.props;

    let files = 0

     refund_request && 
     refund_request.messages && 
     refund_request.messages.length && 
     refund_request.messages.forEach(element => element.files.length && files++);

    return !!files
  }

  render() {
    const {
      refund_requests: { refund_request },
    } = this.props;
    const {
      loading,
      open,
      openDialog,
      loadingBtn,
      message,
      sendMessageLoading,
    } = this.state;

    if (loading) return <Loader />;

    return (
      <TransitionedBlock>
        <div className="item_page container">
          <div className="flex-center-btw title-block-page">
            <div className="title-page">
              <BackLink />
              <h1>Заявка на возврат к заказу {refund_request.ordernum}</h1>
            </div>
          </div>
          <div className="item_page_block">
            <div className="block_wrapper status_wrapper">
              <div className="title_items">Статус заказа</div>
              {refund_request.status !== "processing" ? (
                <div>
                  <div className="descriptions_items">Статус</div>
                  <div className="title_items_mini block_status_info indent_none">
                    <span
                      className="status"
                      style={{
                        background: `${getStatusColor(refund_request.status)}`,
                      }}
                    />
                    {getStatusRefundRequest(refund_request.status)}
                  </div>
                  {
                    refund_request.status === "waiting_for_documents" &&
                      <div className="wrapper_btn_items mt-20">
                        <DefaultButton
                          variant="outlined"
                          classes="waiting_for_details"
                          onClick={() => {
                            this.setState({ loadingBtn: true });
                            this.handleDialog("waiting_for_details");
                            this.setState({ loadingBtn: false });
                          }}
                          loading={loadingBtn}
                          disabled={loadingBtn}
                        >
                          Ожидаем деталь
                        </DefaultButton>
                        <DefaultButton
                          variant="outlined"
                          classes="delete_btn"
                          onClick={this.toggleDialog}
                        >
                          Отказать
                        </DefaultButton>
                        <DefaultButton
                          classes="default_button_contained_green"
                          onClick={this.toggleDialogOpen}
                        >
                          Одобрить
                        </DefaultButton>
                      </div>
                  }
                  {
                    refund_request.status === "waiting_for_details" &&
                      <div className="wrapper_btn_items mt-20">
                        <DefaultButton
                          variant="outlined"
                          classes="waiting_for_documents"
                          onClick={() => {
                            this.setState({ loadingBtn: true });
                            this.handleDialog("waiting_for_documents");
                            this.setState({ loadingBtn: false });
                          }}
                          loading={loadingBtn}
                          disabled={loadingBtn}
                        >
                          Ожидаем документы
                          </DefaultButton>
                          <DefaultButton
                            variant="outlined"
                            classes="delete_btn"
                            onClick={this.toggleDialog}
                          >
                            Отказать
                        </DefaultButton>
                        <DefaultButton
                          classes="default_button_contained_green"
                          onClick={this.toggleDialogOpen}
                        >
                          Одобрить
                        </DefaultButton>
                      </div>
                  }
                </div>
              ) : (
                <div className="wrapper_btn_items">
                  <DefaultButton
                    variant="outlined"
                    classes="waiting_for_documents"
                    onClick={() => {
                      this.setState({ loadingBtn: true });
                      this.handleDialog("waiting_for_documents");
                      this.setState({ loadingBtn: false });
                    }}
                    loading={loadingBtn}
                    disabled={loadingBtn}
                  >
                    Ожидаем документы
                  </DefaultButton>
                  <DefaultButton
                    variant="outlined"
                    classes="waiting_for_details"
                    onClick={() => {
                      this.setState({ loadingBtn: true });
                      this.handleDialog("waiting_for_details");
                      this.setState({ loadingBtn: false });
                    }}
                    loading={loadingBtn}
                    disabled={loadingBtn}
                  >
                    Ожидаем деталь
                  </DefaultButton>
                  <DefaultButton
                    variant="outlined"
                    classes="delete_btn"
                    onClick={this.toggleDialog}
                  >
                    Отказать
                  </DefaultButton>
                  <DefaultButton
                    classes="default_button_contained_green"
                    onClick={this.toggleDialogOpen}
                  >
                    Одобрить
                    </DefaultButton>
                </div>
              )}
            </div>
            <div className="line_wrapper" />
            <div className="block_wrapper">
              <div className="title_items">Информация о документе</div>
              <div className="half_block_wrapper_items">
                {
                  refund_request && refund_request.document !== null
                    ?
                    (
                      <div>
                        <div className="descriptions_items">Номер</div>
                        <div className="title_items_mini">
                          {refund_request && refund_request.document && refund_request.document.number}
                        </div>
                        <div className="descriptions_items">Дата</div>
                        <div className="title_items_mini">
                          {
                            refund_request
                            && refund_request.document
                            && refund_request.document.date
                            && moment(refund_request.document.date).format('DD.MM.YYYY HH:mm')
                          }
                        </div>
                        {
                          refund_request.document.upd_number &&
                          <>
                            <div className="descriptions_items">Номер УПД</div>
                            <div className="title_items_mini">
                              {refund_request.document.upd_number}
                            </div>
                          </>
                        }
                      </div>
                    )
                    :
                    (
                      <div>
                        <div className="title_items_mini">Информация не найдена</div>
                      </div>
                    )
                }
              </div>
            </div>
            <div className="line_wrapper" />
            <div className="block_wrapper">
              <div className="title_items">Информация о компании</div>
              <div className="half_block_wrapper_items">
                {refund_request && refund_request.company !== null && (
                  <div>
                    <div className="descriptions_items">Название</div>
                    <div className="title_items_mini">
                      {refund_request && refund_request.company}
                    </div>
                  </div>
                )}
                <div>
                  <div className="descriptions_items">Электронный адрес</div>
                  <div className="title_items_mini">
                    {refund_request.company_email}
                  </div>
                </div>
              </div>
              <div className="half_block_wrapper_items">
                <div>
                  {refund_request.phones.map((phone, idPhone) => (
                    <Fragment key={idPhone}>
                      <div className="descriptions_items">Телефон</div>
                      <div className="title_items_mini">
                        {phone.phone_number}
                        <div className="info-social-wrapper">
                          {!!phone.telegram ? (
                            <img src={TelegramIcon} alt="telegram icon" />
                          ) : null}
                          {!!phone.viber ? (
                            <img src={ViberIcon} alt="viber icon" />
                          ) : null}
                          {!!phone.whats_app ? (
                            <img src={WhatsappIcon} alt="whatsapp icon" />
                          ) : null}
                        </div>
                      </div>
                    </Fragment>
                  ))}
                </div>
              </div>
            </div>
            <div className="line_wrapper" />
            {!!refund_request.product_info &&
            refund_request.product_info !== null ? (
              <div className="block_wrapper">
                <div className="title_items">Сведения о товаре</div>
                <div className="descriptions_items">Название</div>
                <div className="title_items_mini">
                  {refund_request.product_info.name}
                </div>
                <div className="half_block_wrapper_items">
                  <div>
                    <div className="descriptions_items">Артикул</div>
                    <div className="title_items_mini">
                      {refund_request.product_info.article_number}
                    </div>
                  </div>
                  <div>
                    <div className="descriptions_items">Производитель</div>
                    <div className="title_items_mini">
                      {refund_request.product_info.producer}
                    </div>
                  </div>
                </div>
                <div className="half_block_wrapper_items">
                  <div>
                    <div className="descriptions_items">Количество</div>
                    <div className="title_items_mini indent_none">
                      {refund_request.product_info.amount}
                    </div>
                  </div>
                  <div>
                    <div className="descriptions_items">Сумма</div>
                    <div className="title_items_mini indent_none">
                      {splitByCommas(refund_request.product_info.price)} ₽
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            <div className="line_wrapper" />
            <div className="title_items">Комментарии</div>
            {!!refund_request && refund_request.messages && refund_request.messages.length ? (
              <div className="flex flex-column gap-20">
                {refund_request.messages
                  //сортировка по времени публикации
                  .sort((a, b) => a.pub_date.localeCompare(b.pub_date))
                  .map((message, index) => (
                    <CommentComponent data={message} key={index} />
                  ))}
              </div>
            ) : null}
            <TextareaAutosize
              className="mt-50"
              placeholder="Ваше сообщение..."
              value={message}
              onChange={this.handleChangeTextarea}
            />
            <div className="flex mt-20 gap-20">
              <DefaultButton
                variant="outlined"
                classes="default_button_contained"
                loading={sendMessageLoading}
                disabled={!message}
                onClick={this.sendMessage}
              >
                Ответить
              </DefaultButton>
              <UploadFile
                uploadFile={this.uploadFile}
                isSending={sendMessageLoading}
              />
            </div>
            {this.hasFiles() ? (
              <Fragment>
                <div className="line_wrapper" />
                <div className="block_wrapper">
                  <div className="title_items">Прикрепленные файлы</div>
                  {refund_request.messages.map((item, idFiles) => {
                    if (item.files.length) {
                      return item.files.map((file, index) => (
                        <div
                          className="file_add"
                          key={index}
                          onClick={() => this.downloadFile(file.file)}
                        >
                          <img src={FileIcon} alt="FileIcon" />
                          <span>{file.name}</span>
                        </div>
                      ));
                    }
                    return null;
                  })}
                </div>
              </Fragment>
            ) : null}
          </div>
          {refund_request.status === "processing" &&
          refund_request.other_requests &&
          refund_request.other_requests.length > 0 ? (
            <div className="item_page_block">
              <div className="refund_requests_page_block">
                <div className="title_items">Другие заявки по заказу</div>
                <div className="item_page_table">
                  <div className="transactions_table">
                    <div className="table_container transactions_columns">
                      <div className="table_header">
                        <div className="table_row">
                          <div className="row_item">Дата/время</div>
                          <div className="row_item">К-во товара</div>
                          <div className="row_item">Сумма</div>
                          <div className="row_item">Статус</div>
                        </div>
                      </div>
                      <div className="table_body">
                        {refund_request.other_requests.map(
                          (request, idRequest) => (
                            <div className="table_row" key={idRequest}>
                              <div className="row_item">
                                {moment(request.date).format(
                                  "DD.MM.YYYY HH:mm"
                                )}
                              </div>
                              <div className="row_item">{request.amount}</div>
                              <div className="row_item">
                                {splitByCommas(request.price)} ₽
                              </div>
                              <div className="row_item">
                                <div className="block_status_info">
                                  <span
                                    className="status"
                                    style={{
                                      background: `${getStatusColor(
                                        request.status
                                      )}`,
                                    }}
                                  />
                                  <span className="text">
                                    {getStatusRefundRequest(request.status)}
                                  </span>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          <DialogComponent open={open} onClose={this.toggleDialog}>
            <div className="title-dialog">Отказать по заявке на возврат</div>
            <div className="descriptions_dialog_component">Вы уверены?</div>

            <div className="btn-wrapper order-dialog-btn">
              <DefaultButton
                variant="outlined"
                classes="cancel_btn"
                onClick={this.toggleDialog}
              >
                Отмена
              </DefaultButton>
              <DefaultButton onClick={() => this.handleDialog("denied")}>
                Отказать
              </DefaultButton>
            </div>
            <IconButton
              classes={{
                root: "dialog_close_button",
              }}
              onClick={this.toggleDialog}
            >
              <img src={CloseDialogIcon} alt="close icon" />
            </IconButton>
          </DialogComponent>
          <DialogComponent open={openDialog} onClose={this.toggleDialogOpen}>
            {refund_request && refund_request.company_role !== 'wholesale_customer' ? (
              <RefundRequestsStatusForm
              requestId={refund_request.id}
              toggleDialogOpen={this.toggleDialogOpen}
              handleDialog={this.handleDialog}
              loading={loadingBtn}
              />
            ) : (
              <Fragment>
                <div className="title-dialog">
                    Одобрить заявку на возврат
                </div>
                <div className="descriptions_dialog_component">Вы уверены?</div>
                <div className="btn-wrapper order-dialog-btn">
                    <DefaultButton
                        variant="outlined"
                        classes="cancel_btn"
                        onClick={this.toggleDialogOpen}
                    >
                        Отмена
                    </DefaultButton>
                    <DefaultButton
                        onClick={() => {
                            this.setState({loadingBtn: true});
                            this.handleDialog("complete");
                            this.setState({loadingBtn: false});
                        }}
                        loading={loadingBtn}
                        disabled={loadingBtn}
                    >
                        Одобрить
                    </DefaultButton>
                </div>
              </Fragment>
            )}
            
            <IconButton
              classes={{
                root: "dialog_close_button",
              }}
              onClick={this.toggleDialogOpen}
            >
              <img src={CloseDialogIcon} alt="close icon" />
            </IconButton>
          </DialogComponent>
        </div>
      </TransitionedBlock>
    );
  }
}

const mapStateToProps = ({ refund_requests }) => ({
  refund_requests,
});

const mapDispatchToProps = {
  getSpecificRefundRequest,
  patchRefundRequestStatus,
  postRefundRequestConfirmStatus,
  postRefundRequestOffers,
  postDialogMessage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withUploadFile(RefundRequestsItems));
