import React from "react";
import Select, { components } from "react-select";

import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import "./SelectComponent.scss";

const ClearIndicator = (props) => {
    const {
        children = <CloseIcon />,
        innerProps: { ref, ...restInnerProps },
    } = props;
    return (
        <div {...restInnerProps} ref={ref}>
            <div className="select_close_btn">{children}</div>
        </div>
    );
};

const DropdownIndicator = (props) => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <div
                    className={
                        props.selectProps.menuIsOpen
                            ? "select_indicator indicator_active"
                            : "select_indicator"
                    }
                >
                    <ExpandMoreIcon />
                </div>
            </components.DropdownIndicator>
        )
    );
};

const SelectComponent = ({
    value,
    options,
    loading,
    change,
    placeholder,
    isClearable,
    classes,
    isSearchable = true,
    onKeyDown = undefined,
}) => (
    <Select
        className={`select_component ${classes}`}
        classNamePrefix="select"
        isDisabled={false}
        isLoading={loading}
        isClearable={!isClearable}
        isSearchable={isSearchable}
        onKeyDown={onKeyDown}
        name="color"
        value={value}
        options={options}
        onChange={change}
        loadingMessage={() => "Loading filters..."}
        placeholder={placeholder}
        components={{ ClearIndicator, DropdownIndicator }}
    />
);

export default SelectComponent;
