import React, {Component} from 'react';
import Pagination from '../../HelperComponents/Pagination/Pagination';
import NoItems from '../../HelperComponents/NoItems/NoItems';
import moment from "moment/moment";

import { splitByCommas } from "../../../helpers/functions";

import './TransactionsTable.scss';
import OrdersIcon from "../../../assets/image/no_orders.png";
import { Link } from 'react-router-dom';

class TransactionsTable extends Component {
    state = {
        activePage: 1
    };

    getCompanyId = (company = '') => {
        const { all_companies } = this.props;
        
        return all_companies 
            && all_companies.find(item => item.company === company)
            && all_companies.find(item => item.company === company).id
    }

    changePage = (pageNumber) => {
        const { doRequest } = this.props;
        this.setState({ activePage: pageNumber });
        setTimeout(() => {
            doRequest(pageNumber);
        }, 0);
    };

    render(){
        const { activePage } = this.state;
        const { all_transactions, linkType } = this.props;

        return (
            <div className="transactions_table_wrapper">
                <div className="transactions_table">
                    <div className="table_container transactions_columns">
                        <div className="table_header">
                            <div className="table_row">
                                <div className="row_item">Дата/время</div>
                                <div className="row_item">Компания</div>
                                <div className="row_item">Сумма</div>
                                <div className="row_item">Тип транзакции</div>
                                <div className="row_item">Баланс</div>
                                <div className="row_item">Номер выдачи заказа</div>
                            </div>
                        </div>
                        <div className="table_body">
                            {all_transactions && all_transactions.count > 0 ?
                                all_transactions.results.transactions.map((item, idItem) => (
                                    linkType ? 
                                    (
                                        <Link to={`/admin/transactions/${this.getCompanyId(item.company)}`} className="table_row" key={item.id}>
                                            <div className="row_item"> {moment(item.date).format('DD.MM.YYYY HH:mm')} </div>
                                            <div className="row_item"> {item.company} </div>
                                            <div className="row_item">
                                                <div className={item.type_transaction === 'Покупка товара' ? "red_text" : "green_text"}>
                                                    {splitByCommas(item.amount)} ₽
                                                </div>
                                            </div>
                                            <div className="row_item"> {item.type_transaction} </div>
                                            <div className="row_item">
                                                {splitByCommas(item.balance)} ₽
                                            </div>
                                            <div className="row_item">{item.document_number}</div>
                                        </Link>
                                    ) : (
                                        <div className="table_row" key={idItem}>
                                            <div className="row_item"> {moment(item.date).format('DD.MM.YYYY HH:mm')} </div>
                                            <div className="row_item"> {item.company} </div>
                                            <div className="row_item">
                                                <div className={item.type_transaction === 'Покупка товара' ? "red_text" : "green_text"}>
                                                    {splitByCommas(item.amount)} ₽
                                                </div>
                                            </div>
                                            <div className="row_item"> {item.type_transaction} </div>
                                            <div className="row_item">
                                                {splitByCommas(item.balance)} ₽
                                            </div>
                                            <div className="row_item">{item.document_number}</div>
                                        </div>
                                    )
                                ))
                                :
                                <NoItems>
                                    <div className="box-img"><img src={OrdersIcon} alt="edit icon"/></div>
                                    <div className="title" >Транзакций не найдено</div>
                                </NoItems>
                            }
                        </div>
                    </div>
                    { all_transactions.count > 10 && 
                        <div>
                            <Pagination
                                active={activePage}
                                pageItemsCount={10}
                                pageTotalCount={all_transactions.count}
                                onChange={(e) => this.changePage(e.selected + 1)}
                            />
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default TransactionsTable;