import React, { Component, Fragment } from "react";
import { Field, reduxForm } from "redux-form";
import RenderField from "../../HelperComponents/RenderField/RenderField";
import DefaultButton from "../../Buttons/DefaultButton/DefaultButton";
import moment from "moment";
import { bindActionCreators } from "redux";
import { getPriceLists } from '../../../actions/priceListActions';
import { getUPDNumbers } from '../../../actions/refundRequestsActions';
import { connect } from "react-redux";
import NoItems from "../../HelperComponents/NoItems/NoItems";
import SelectComponent from "../../HelperComponents/SelectComponent/SelectComponent";
import PricesIcon from "../../../assets/image/no_prices.png";
import CheckboxHelper from "../../PriceList/CheckboxHelper/CheckboxHelper";
import TooltipMessage from "../../HelperComponents/TooltipMessage/TooltipMessage";
import ErrorIcon from "@material-ui/icons/Error";
import './RefundRequestsStatusForm.scss';

class RefundRequestsStatusForm extends Component {

  state = {
    tab: 0,
    open: [],
    updNumbers: [],
    selectedUpdNumber: undefined,
    checkboxObj: {}
  }

  componentDidMount() {
      const {requestId, getPriceLists, getUPDNumbers} = this.props;

      getPriceLists().then(res => {
          if (res.payload && res.payload.status && res.payload.status === 200) {
              this.setState({loading: false});
              this.handlerChecked();
          }
      });

      getUPDNumbers(requestId).then(res => {
        if (res.payload && res.payload.status && res.payload.status === 200) {
            this.setState({updNumbers: res.payload.data || []});
        }
      })
  }

  toggleCheck = (id) => {
      const {checkboxObj} = this.state;

      if (!checkboxObj[id]) {
          checkboxObj[id] = [];
      }
      if (checkboxObj[id].length === 0) {
          checkboxObj[id] = [];
      } else {
          delete checkboxObj[id];
      }
      this.setState({
          checkboxObj
      });
  };

  toggleChildChange = (parentId, childId, lenghtChild) => {
      const {checkboxObj} = this.state;

      if (!checkboxObj[parentId]) {
          checkboxObj[parentId] = [];
      }

      if (!checkboxObj[parentId].includes(childId)) {
          checkboxObj[parentId] = [childId];
      } else {
          checkboxObj[parentId] = checkboxObj[parentId].filter(el => el !== childId);
      }

      this.setState({
          checkboxObj
      });
  };

  toggleOpen = id => {
      const {open} = this.state;
      if (open.includes(id)) {
        this.setState(({open}) => ({
          open: open.filter(el => el !== id)
        }));
      } else {
          this.setState(({open}) => ({
              open: [id]
          }));
      }
  };

  handlerChecked = () => {
      const {price_list: {price_lists}} = this.props;
      const {checkboxObj} = this.state;

      price_lists.forEach(elParent => {
          if (elParent.price_list.length !== 0) {
              elParent.price_list.forEach(elChild => {
                  if (elChild.superadmin_mark) {
                      if (!checkboxObj[elParent.id]) {
                          checkboxObj[elParent.id] = [];
                      }
                  }
              })
          }
      });

      this.setState({
          checkboxObj
      });

  };

  handleSelectUpdNumber = (formValue) => {
    this.setState({selectedUpdNumber: formValue})
  };

  changeTab = (tab) => {
    this.setState({ tab });
  };

  required = value => (value || typeof value === 'number' ? undefined : 'Поле обязательно для заполнения');

  render() {
    const {
      submitting,
      pristine,
      valid,
      toggleDialogOpen,
      handleDialog,
      loading,
      price_list: {price_lists}
    } = this.props;
    const { tab, open, selectedUpdNumber, updNumbers, checkboxObj } = this.state;

    return (
      <form
        className="refund_requests_status__form"
        onSubmit={(event) => {
          event.preventDefault()
          if (tab === 0 ) {
              handleDialog("complete", {
                number: event.target.number.value,
                date: moment(event.target.date.value).format(
                  "YYYY-MM-DDTHH:mm:ss.000"
                ),
                upd_number: selectedUpdNumber && selectedUpdNumber.value 
              })
            } else {
              handleDialog("completeByPriceList", {
                offers: {
                  price_list: checkboxObj[open[0]][0],
                  location: event.target.location.value
                }
              })
          }
        }}
      >
        <h3 className="title-dialog">
          Одобрить заявку на возврат
        </h3>
        <div className="tab_custom">
          <button 
            className={`${tab === 0 ? "active" : ""}`}
            onClick={(e) => {
              e.preventDefault()
              this.changeTab(0)
            }}
          >
            По номеру и дате документа
          </button>
          <button
            className={`${tab === 1 ? "active" : ""}`}
            onClick={(e) => {
              e.preventDefault()
              this.changeTab(1)
            }}
          >
            По прайс-листу
          </button>
        </div>
        <div className="flex flex-column gap-20">
          { tab === 0 && 
          <Fragment>
            <Field
              name="number"
              type="text"
              component={RenderField}
              label="Номер документа"
              validate={[this.required]}
            />
            <Field
              name="date"
              type="date"
              component={RenderField}
              validate={[this.required]}
            />
            {
              updNumbers && 
              !!updNumbers.length && 
              <SelectComponent 
                  classes="form-item"
                  placeholder={'Выберите номер УПД'} 
                  value={selectedUpdNumber} 
                  change={this.handleSelectUpdNumber}
                  options={updNumbers.map(({upd_number})=>({label: `Номер УПД ${upd_number}`, value: upd_number}))} 
              />
            }
          </Fragment>}
          { tab === 1 && 
          <Fragment>
            <Field
              name="location"
              type="text"
              component={RenderField}
              label="Место хранения"
            />
            
            <div className="scroll_block">
              {price_lists && price_lists.length && price_lists.length === 0 ?
                  <NoItems>
                      <div className="box-img"><img src={PricesIcon} alt="edit icon"/></div>
                      <div className="title">Пока нет ни одного прайс-листа</div>
                  </NoItems>
                  :
                  price_lists.map(({company, price_list, id}) => {
                      return(
                          price_list.length !== 0 ?
                              <CheckboxHelper
                                  key={id}
                                  id={id}
                                  price_list={price_list}
                                  company={company}
                                  toggleOpen={() => this.toggleOpen(id)}
                                  open={open}
                                  toggleCheck={() => this.toggleCheck(id, price_list)}
                                  checkboxObj={checkboxObj}
                                  toggleChildChange={this.toggleChildChange}
                                  type={"approve"}
                              />
                              : null
                      )
                  })
              }
            </div>
          </Fragment>}
        </div>
        <div className="btn-wrapper order-dialog-btn">
          <DefaultButton
            variant="outlined"
            classes="cancel_btn"
            onClick={toggleDialogOpen}
          >
            Отмена
          </DefaultButton>
          <DefaultButton
            disabled={tab === 0
              ? (submitting || pristine || !valid)
              : !(checkboxObj && checkboxObj[open[0]] && checkboxObj[open[0]][0])}
            loading={loading}
            formAction
          >
            Одобрить
          </DefaultButton>
          <div className="download-file_error flex-center">
            {tab === 1 && !(checkboxObj && checkboxObj[open[0]] && checkboxObj[open[0]][0]) &&
              <TooltipMessage
                  text={'Выберите прайс-лист'}
                  delay={200}
                  error
                  position="right"
                  classes=""
              >
                  <ErrorIcon />
              </TooltipMessage>
            }
          </div>
        </div>
      </form>
    );
  }
}

RefundRequestsStatusForm = reduxForm({
  form: "RefundRequestsStatusForm",
})(RefundRequestsStatusForm);

function mapStateToProps(state) {
  return {
      price_list: state.price_list
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
      getPriceLists,
      getUPDNumbers,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(RefundRequestsStatusForm);
